<!--
  * @desc:
  * @author: 肖
  * @create_date: 2021/6/5
  * @BSD
-->
<template>
  <div>
    <!-- 签发弹框 -->
    <van-popup v-model="show" position="center" class="detail-popup" background-color="#fff">
      <div class="buy-confirm-modal panel-modal">
        <div class="content">
          <div class="title">重抽券不足，请购买</div>
          <div class="main">
            <div class="image-wrap">
              <img src="../assets/nsmh_icon_quan_big.png" mode="" />
            </div>
            <div class="num-wrap">
              <template v-for="(item, index) in numArr">
                <div class="num-item" :key="index" :class="{'active': curNum == item}" @click="changeNum(item)">{{ item }}</div>
              </template>
            </div>
          </div>
          <div class="btn-line">
            <div class="btn-normal" @click="closeModal">
              <img src="../assets/nsmh_text_quxiao.png" mode="" />
            </div>
            <div class="btn-save" @click="gotoBuyLottery">
              ¥3
              <img src="../assets/nsmh_text_goumai.png" mode="" />
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
  import { buyLotteryTicket, getOrderStatus } from '@/common/api/shop.js'
  export default {
    name:"buy-mh",
    props: {
      group_id: [String, Number]
    },
    data() {
      return {
        show: false,
        numArr: [5,1,10],
        curNum: 1
      };
    },
    methods:{
      changeNum (item) {
        this.curNum = item
      },
      open () {
        this.show = true
      },
      closeModal () {
        this.show = false
      },
      // 购买盲盒
      gotoBuyLottery () {
        buyLotteryTicket({
          data: {
            num: this.curNum,
            type: 2, // 1盲盒券2重抽券
            group_id: this.group_id,
            openid: localStorage.getItem('openid'),
            is_use_coupons: localStorage.getItem('usedticket') == 'true' ? '1' : '0'
          },
          success: (res) => {
            console.log(res)
            if (res.code == 200) {
              window.location.href = res.data.request_info.mweb_url + '&redirect_url=https%3A%2F%2Fh5test.agpt.vip%2F%23%2FboxOpen'
              // console.log({
              //   provider: 'wxpay',
              //   orderInfo: res.data.local_order_info, //微信、支付宝订单数据
              //   timeStamp: new Date().getTime(),
              //   nonceStr: res.data.request_info.nonce_str,
              //   package: '',
              //   signType: 'MD5',
              //   paySign: res.data.request_info.sign
              //
              // })
              // console.log(res.data.local_order_info)
              //
              // uni.requestPayment({
              //   provider: 'wxpay',
              //   orderInfo: res.data.local_order_info, //微信、支付宝订单数据
              //   timeStamp: res.data.request_info.timeStamp,
              //   nonceStr: res.data.request_info.nonceStr,
              //   package: res.data.request_info.package,
              //   signType: 'MD5',
              //   paySign: res.data.request_info.paySign,
              //   success: result => {
              //     getOrderStatus({
              //       data: {
              //         order_no: res.data.local_order_info.order_no,
              //       },
              //       success: () => {
              //         this.closeModal()
              //         this.$emit('refresh')
              //       }
              //     })
              //   },
              //   fail: function (err) {
              //     console.log('fail:' + JSON.stringify(err));
              //   }
              // });
            }
          }
        })
      },
    }
  }
</script>

<style lang="scss">
  $mainColor: #EE7F18;
  .detail-popup {
    background-image: linear-gradient(134deg, rgba(247,247,247,0.90) 0%, rgba(248,246,246,0.85) 68%, rgba(255,255,255,0.90) 100%);
    background-color: transparent;
    border: 12px solid #FFFFFF;
    border-radius: 50px;
    width: 640px;
    height: 504px;
  }
  .buy-confirm-modal {
    background: transparent;
    border-radius: 50px;
    box-sizing: border-box;
    & .content {
      background: none;
      padding: 30px;
      box-sizing: border-box;
      // background-image: linear-gradient(134deg, rgba(248,195,216,0.90) 0%, rgba(248,246,246,0.85) 68%, rgba(255,255,255,0.90) 100%);
      & .title {
        font-size: 36px;
        color: $mainColor;
        letter-spacing: 0;
        text-align: center;
        line-height: 48px;
        height: 48px;
        font-weight: bold;
        margin-bottom: 20px;
      }
      & .main {
        & .image-wrap {
          margin-bottom: 22px;
          font-size: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          & img {
            width: 160px;
            height: 160px;
          }
        }
        & .num-wrap {
          display: flex;
          align-items: center;
          justify-content: center;
          & .num-item {
            border: 2px solid #FFFFFF;
            border-radius: 50px;
            width: 112px;
            height: 56px;
            font-size: 40px;
            color: $mainColor;
            letter-spacing: 0;
            text-align: center;
            line-height: 40px;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 24px;
            &.active {
              background: rgba(255,255,255,0.50);
            }
          }
        }
      }
      & .btn-line {
        margin-top: 48px;
        & .btn-normal {
          opacity: 0.9;
          background-image: linear-gradient(-45deg, #FFFFFF 1%, #F4F4F4 30%, #E7E7E7 100%);
          box-shadow: 0 -10px 24px 0 rgba(200,200,200,0.60), 8px 6px 14px 0 #DADADA;
          border-radius: 63px;
          width: 256px;
          height: 88px;
          & image {
            width: 118px;
            height: 52px;
          }
        }
        & .btn-save {
          opacity: 0.9;
          background-image: linear-gradient(-45deg, #FFFFFF 1%, #F4F4F4 30%, #E7E7E7 100%);
          box-shadow: 0 -10px 24px 0 rgba(200,200,200,0.60), 8px 6px 14px 0 #DADADA;
          border-radius: 63px;
          width: 256px;
          height: 88px;
          font-size: 48px;
          color: $mainColor;
          letter-spacing: 0;
          text-align: center;
          line-height: 24px;
          font-weight: bold;
          display: flex;
          align-items: center;
          justify-content: center;
          & image {
            width: 116px;
            height: 52px;
          }
        }
      }
    }
  }
</style>

